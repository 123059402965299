// @flow
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { get } from 'utils/api';
import { getOrgIdAndPropertyIdFromURL } from 'utils';
import { IS_DEV } from 'utils/constants';
import {
  HOUSEHOLD_TYPE_AFFORDABLE,
  HOUSEHOLD_TYPE_CONVENTIONAL,
  HOUSEHOLD_TYPE_MIXED,
} from 'utils/constants/household';
import { BEDROOM_OPTIONS } from 'components/Organisms/WidgetMyUnit/constants';
import type { AppContextType, MarketingInfo } from 'types/context';
import { getLocalAuth } from '../utils/auth';
import { PAYMENT_METHODS } from 'utils/constants/paymentMethods';

export const ENDPOINT_APP_CONTEXT = '/app-context';
export const AppContext: AppContextType = React.createContext();

export const AppContextProvider = ({ children }) => {
  const { authToken } = getLocalAuth();
  const location = useLocation();
  const language = navigator.language.split(/[-_]/)[0];

  const {
    authenticationPortal,
    assigningUnitsAtApplication,
    paymentsV1,
    uploadDocumentsInPortal,
    displayExecutedLeaseInPortal,
    propertySettingApplyWithoutAUnit,
    assigningUnitsMoveInDates,
    paymentsAutopay,
  } = useFlags();
  /**
   * Initial AppContext from `pathname`
   */

  const { organizationId, propertyId } = useMemo(() => {
    return getOrgIdAndPropertyIdFromURL(location.pathname, location.search);
  }, [location.pathname, location.search]);

  // updated in WidgetMyApplication
  const [applicationFormComplete, setApplicationFormComplete] = useState(false);

  const [appContext, setAppContext] = useState({
    organizationId,
    propertyId,
    marketingInfo: { propertyClass: null },
    configs: {
      applyWithoutUnit: false,
      isUnitSelectionEnabled: false,
    },
    features: {
      isUploadDocumentsInPortalEnabled: false,
      isAssigningUnitsAtApplicationEnabled: false,
      isPaymentsV1Enabled: false,
      isAssigningUnitsMoveInDatesEnabled: false,
      propertySettingApplyWithoutAUnitFlag: false,
      paymentsAutopayFlag: false,
    },
  });

  const { numberOfBedsOptions } = appContext;

  const nBedSelectOptions = useMemo(() => {
    return BEDROOM_OPTIONS.filter((brOption) =>
      numberOfBedsOptions?.some((nBed) => Number(brOption.value) === nBed),
    );
  }, [numberOfBedsOptions]);

  useEffect(() => {
    const fetchAsyncOptions = async () => {
      const url = `/portal/${organizationId}/${propertyId}${ENDPOINT_APP_CONTEXT}`;
      const portalAppContext: AppContextType = await get(url);

      // Initialize GA tracking if the property has been set up
      const trackingId = portalAppContext?.googleAnalyticsTracking?.trackingId;
      if (trackingId && !ReactGA.isInitialized) {
        ReactGA.initialize([{ trackingId }]);
      }

      setAppContext((prevAppContext: AppContextType) => ({
        ...prevAppContext,
        ...portalAppContext,
      }));
    };

    if (organizationId && propertyId) {
      try {
        fetchAsyncOptions();
      } catch (e) {
        if (IS_DEV) {
          console.error(e);
        }
      }
    }
  }, [authToken, organizationId, propertyId]);

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location.pathname]);

  const marketingInfo: MarketingInfo = appContext?.marketingInfo ?? {};

  const isConventionalProperty = Boolean(
    marketingInfo?.propertyClass === HOUSEHOLD_TYPE_CONVENTIONAL,
  );
  const isAffordableProperty = Boolean(
    marketingInfo?.propertyClass === HOUSEHOLD_TYPE_AFFORDABLE,
  );
  const isMixedProperty = Boolean(
    marketingInfo?.propertyClass === HOUSEHOLD_TYPE_MIXED,
  );

  const hasSomeOrNoCommercialFloorPlans = ['SOME', 'NONE'].includes(
    marketingInfo?.hasCommercialFloorPlans,
  );

  const isUnitSelectionEnabled =
    assigningUnitsAtApplication &&
    isConventionalProperty &&
    hasSomeOrNoCommercialFloorPlans
      ? appContext?.configs?.isUnitSelectionEnabled ?? false
      : false;

  // to help manage feature flagged features
  const features = {
    isAssigningUnitsAtApplicationEnabled: isUnitSelectionEnabled,
    isAuthenticationPortalEnabled: authenticationPortal,
    isUploadDocumentsInPortalEnabled: uploadDocumentsInPortal,
    isPaymentsV1Enabled: paymentsV1,
    displayExecutedLeaseInPortal,
    isAssigningUnitsMoveInDatesEnabled: assigningUnitsMoveInDates,
    propertySettingApplyWithoutAUnitFlag: propertySettingApplyWithoutAUnit,
    assigningUnitsAtApplicationFlag: assigningUnitsAtApplication,
    paymentsAutopayFlag: paymentsAutopay,
  };

  const paymentProviderName =
    appContext?.paymentProvider?.paymentProvider ?? null;
  const showFortressPaymentMethods =
    features.isPaymentsV1Enabled &&
    paymentProviderName === PAYMENT_METHODS.FORTRESS;
  const isDomusoActive = marketingInfo?.isDomusoActive ?? false;
  const isPayOnlineAvailable =
    isDomusoActive !== marketingInfo?.isPayLeaseActive;
  const hasPaymentProvider = isPayOnlineAvailable || showFortressPaymentMethods;
  const propertySettings = {
    isUnitSelectionEnabled:
      appContext?.configs?.isUnitSelectionEnabled ?? false,
    isApplyWithoutUnitEnabled: appContext?.configs?.applyWithoutUnit ?? false,
  };

  const isUnitRequired = features.propertySettingApplyWithoutAUnitFlag
    ? propertySettings.isApplyWithoutUnitEnabled === false
    : true; // To avoid issues if flag is off

  const defaultContext = {
    ...appContext,
    language,
    applicationFormComplete,
    authenticationPortal,
    features,
    isAffordableProperty,
    isConventionalProperty,
    isMixedProperty,
    organizationId,
    propertyId,
    setAppContext,
    setApplicationFormComplete,
    isUnitRequired,
    nBedSelectOptions,
    propertySettings,
    paymentProviderName,
    showFortressPaymentMethods,
    isPayOnlineAvailable,
    hasPaymentProvider,
    isDomusoActive,
  };

  return (
    <AppContext.Provider value={defaultContext}>{children}</AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default { AppContext, AppContextProvider };
